import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const UserProtectedRoute = ({ children }) => {
    const userLogin = useSelector((state) => state.userLogin);

    if (!userLogin || !userLogin.UserInfo) {
        return <Navigate to="/" />;
    }

    return children;
};

export default UserProtectedRoute;
