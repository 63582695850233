import axios from "axios"
import { baseUrl } from "../../config/constant"
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  SET_LYRICS,
  SET_CURRENT_LINE_AT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,

  USER_CHECK_EMAIL_REQUEST,
  USER_CHECK_EMAIL_SUCCESS,
  USER_CHECK_EMAIL_FAIL,

  ADMIN_LOGIN_FAIL,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGOUT


} from '../constants/UserConstant'



export const setLyrics = (lyrics, line) => {
  const data = { lyrics: lyrics, line: line }
  console.log(data)
  return (dispatch) => {
    dispatch({
      type: SET_LYRICS,
      payload: data
    })
  }
}
export const setCurrentLineAT = (lyrics, line) => {

  const data = { lyrics: lyrics, line: line }
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_LINE_AT,
      payload: data
    })
  }
}

export const login = (email, password) => async (dispatch) => {
  console.log("inside user action")

  /*  try { */
  dispatch({
    type: USER_LOGIN_REQUEST,
  })
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  await axios.post(
    baseUrl + 'api/web/user_login',
    {
      email,
      password,
    },
    config
  ).then(res => {
    if (res.status == 201) {

      window.location.reload();
    }

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: res.data,
    })

    localStorage.setItem('UserInfo', JSON.stringify(res.data))

  }

  ).catch(error => {
    dispatch({

      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  })

  /* } *//*  catch (error) {
    console.log(error);
    dispatch({

      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message + 'gulab'
          : error.message + 'gulabjamun',
    })
  } */
}

export const registerUser = (email, password, name) => async (dispatch) => {
  console.log("inside registerUser action")
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    await axios.post(
      baseUrl + 'api/web/createuser',
      {
        name,
        email,
        password,
      },
      config).then(res => {


        dispatch({
          type: USER_REGISTER_SUCCESS,
          payload: res.data,
        })



        localStorage.setItem('UserInfo', JSON.stringify(res.data))
        if (res.status == 201) {

          window.location.reload();
        }
      }
      )
      .catch(err => {
        dispatch({
          type: USER_REGISTER_FAIL,
          payload:
            err.response.data.message,
        })

      })

  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}



export const checkexistingemail = (email) => async (dispatch) => {

  try {
    dispatch({
      type: USER_CHECK_EMAIL_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.post(
      baseUrl + 'api/web/checkexistingemail',
      {
        email,
      },
      config
    )

    dispatch({
      type: USER_CHECK_EMAIL_SUCCESS,
      payload: data,
    })


  } catch (error) {
    dispatch({
      type: USER_CHECK_EMAIL_FAIL,
      payload: ""
       /*  error.response && error.response.data.message
          ? error.response.data.message
          : error.message */,
    })
  }
}


export const logout = () => (dispatch) => {
  localStorage.removeItem('UserInfo')
  dispatch({
    type: USER_LOGOUT,
  })

  document.location.href = '/'
}



export const adminLoginCheck = (email, password) => async (dispatch) => {
  console.log("inside user action")
  try {
    dispatch({
      type: ADMIN_LOGIN_REQUEST,
    })
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const { data } = await axios.post(
      baseUrl + 'api/web/adminlogin',
      {
        email,
        password,
      },
      config
    )
    dispatch({
      type: ADMIN_LOGIN_SUCCESS,
      payload: data,
    })


    localStorage.setItem('AdminInfo', JSON.stringify(data))
  } catch (error) {
    dispatch({
      type: ADMIN_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const adminLogout = () => (dispatch) => {
  localStorage.removeItem('AdminInfo')
  dispatch({
    type: ADMIN_LOGOUT,
  })

  document.location.href = '/admin'
}
