import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import waveImg from '../../../assets/images/myImages/digital-wave-audio.svg';
import { baseUrl } from '../../../config/constant';
import UploadBredcumb from './UploadBredcumb';

const MusicUpload = () => {
        const navigate = useNavigate();
        const userLogin = useSelector((state) => state.userLogin);
        const { UserInfo } = userLogin;

        const [fileError, setFileError] = useState('');
        const [uploadFileName, setUploadFileName] = useState('');
        const [loading, setLoading] = useState(false);
        const [uploadSuccess, setUploadSuccess] = useState(false);

        useEffect(() => {
                setLoading(true);
                const userId = UserInfo?._id;

                const config = {
                        headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${JSON.parse(localStorage.getItem('UserInfo')).token}`,
                        },
                };

                axios
                        .post(
                                `${baseUrl}api/web/getuserdata`,
                                { userId },
                                config
                        )
                        .then((res) => {
                                if (res.data.songDraft?.isDrafted) {
                                        setUploadSuccess(true);
                                        setUploadFileName(res.data.songDraft.originalname);
                                }
                        })
                        .catch((err) => console.error(err))
                        .finally(() => setLoading(false));
        }, [UserInfo]);

        const onDrop = useCallback(
                (acceptedFiles) => {
                        const file = acceptedFiles[0];
                        if (file) {
                                setLoading(true);
                                const formData = new FormData();
                                formData.append('userId', UserInfo._id);
                                formData.append('file', file);

                                const config = {
                                        headers: {
                                                'Content-Type': 'multipart/form-data',
                                                Authorization: `Bearer ${JSON.parse(localStorage.getItem('UserInfo')).token}`,
                                        },
                                };

                                axios
                                        .post(`${baseUrl}api/web/musicupload`, formData, config)
                                        .then((res) => {
                                                if (res.data.status === 1) {
                                                        setUploadFileName(res.data.name);
                                                        setFileError('');
                                                        setUploadSuccess(true);
                                                } else {
                                                        setFileError(res.data.message);
                                                        setUploadSuccess(false);
                                                }
                                        })
                                        .catch(() => {
                                                setFileError('Failed to upload');
                                                setUploadSuccess(false);
                                        })
                                        .finally(() => setLoading(false));
                        } else {
                                setFileError('File Not Supported');
                        }
                },
                [UserInfo]
        );

        const { getRootProps, getInputProps, isDragActive } = useDropzone({
                onDrop,
                multiple: false,
                accept: 'audio/mpeg, audio/m4a',
        });

        return (
                <>
                        <UploadBredcumb activeStep={1} />
                        <div className="col p-0">
                                <div className="container musicUploadBase">
                                        <div className="text-center my-5 songUpload">
                                                <h1>Upload Song</h1>
                                                <div className="col songUploadInner py-4" {...getRootProps()}>
                                                        <h2>Upload a vocal track. Make sure your file doesn't include vocals.</h2>
                                                        <div className="upload-container text-center">
                                                                {!loading ? (
                                                                        <div>
                                                                                <label className="songUpload">
                                                                                        <img
                                                                                                src={uploadFileName ? waveImg : 'images/music.png'}
                                                                                                alt="Upload"
                                                                                        />
                                                                                </label>
                                                                                <input {...getInputProps()} />
                                                                                {fileError ? (
                                                                                        <span className="error d-inline-block w-100 mt-3">
                                                                                                {fileError}
                                                                                        </span>
                                                                                ) : (
                                                                                        <span className="d-inline-block w-100 mt-3">
                                                                                                {uploadFileName}
                                                                                        </span>
                                                                                )}
                                                                        </div>
                                                                ) : (
                                                                        <Spinner animation="grow" />
                                                                )}
                                                                {isDragActive && <p>Drop the files here ...</p>}
                                                        </div>
                                                        <h3 className="mt-4">
                                                                We support: m4a, mp3. Max file size: 20 MB. Max length: 10 mins.
                                                        </h3>
                                                </div>
                                                {uploadSuccess && (
                                                        <div className="pt-3 d-flex flex-row justify-content-end">
                                                                <Button
                                                                        className="submitBtn"
                                                                        onClick={(e) => {
                                                                                e.preventDefault();
                                                                                if (
                                                                                        fileError !== 'File Not Supported' &&
                                                                                        fileError !== 'Failed to upload' &&
                                                                                        uploadFileName
                                                                                ) {
                                                                                        navigate('/musicdetails');
                                                                                }
                                                                        }}
                                                                >
                                                                        Next
                                                                </Button>
                                                        </div>
                                                )}
                                                <p className="mt-5">
                                                        <sup>*</sup> By uploading this song, you confirm that you are complying
                                                        with all legal guidelines set forth by our Community Guidelines and
                                                        Terms of Service. If you have any questions, please refer to our FAQ or
                                                        contact YoYo Singer for more assistance.
                                                </p>
                                        </div>
                                </div>
                        </div>
                </>
        );
};

export default MusicUpload;
