import React, { useCallback, useRef, useEffect } from 'react'
import useState from 'react-usestateref'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone'
import { Spinner } from 'react-bootstrap';
import axios from 'axios';
import { baseUrl } from '../../../config/constant';
//import DataTable, { SelectColumnFilter } from '../datatTable/DataTable';
import { Modal, Button, Col, Form } from 'react-bootstrap'

//datatable
import DataTable from 'react-data-table-component';
import { TablePagination } from '@mui/material';
import { IconButton } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

const UserLanding = () => {

    let navigate = useNavigate()
    const userLogin = useSelector((state) => state.userLogin)
    const { UserInfo } = userLogin
    const [loading, setLoading] = useState(false)
    const [serverUrl, setServerUrl] = useState(baseUrl)
    const [songs, setSongs] = useState([])
    const [userId, setUserId, useRefUserId] = useState('')
    //const [tabelData, settabelData] = useState([])
    //const [dataLoad, setdataLoad] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    //pagination-limit
    const [pagenumber, setPagenumber] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [value, setValue] = useState(10)
    const [search, setSearch] = useState("")
    var count = (totalCount / value);
    const PageCount = (Math.ceil(count));
    //sorting
    const [sortColumn, setSortColumn] = useState("createdAt")
    const [sortOrder, setSortOrder] = useState(-1)

    // Row Per Page
    const [rowsPerPage, setRowsPerPage] = useState(value);


    const [deleteId, setDeleteId] = useState("");



    useEffect(() => {
        if (UserInfo == null) {
            navigate("")
        }
        else {
            console.log(UserInfo._id);
            setUserId(UserInfo._id)
            DisplaySongsList()
        }


    }, [value, search]);

    //api-songs-list
    const DisplaySongsList = () => {

        //setLoading(true)


        var local = JSON.parse(localStorage.getItem("UserInfo"))

        var t1 = local['token']

        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${t1}`,
            },
        }
        axios.post(
            baseUrl + 'api/web/getusersong',
            {
                "userId": useRefUserId.current,
                "limit": value,
                "page": pagenumber,
                "search": search,
                "sortColumn": sortColumn,
                "sortOrder": sortOrder
            },
            config
        ).then(res => {

            if (res.data != null) {

                setServerUrl(res.data.serverUrl)

                //songs-count
                setTotalCount(res.data.songsList[0].totalCount.count)

                //index-number
                var temparr = res.data.songsList[0].data;
                temparr.map((temparr, index) => { temparr.serial = index + 1 });
                setSongs(temparr);


            }
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })

    }


    //table-sortion
    const handleSort = (column, sortDirection) => {

        var sortDir = sortDirection == 'asc' ? 1 : -1;
        setSortColumn(column.sortField)
        setSortOrder(sortDir)

        const config = {
            headers: {
                'Content-Type': 'application/json',

            },
        }
        axios.post(
            baseUrl + 'api/web/getusersong',
            {
                "userId": useRefUserId.current,
                "limit": value,
                "page": pagenumber,
                "search": search,
                "sortColumn": column.sortField,
                "sortOrder": sortDir
            },
            config
        ).then(res => {
            if (res.data != null) {

                setSongs(res.data.songsList)
                setServerUrl(res.data.serverUrl)

                //songs-count
                setTotalCount(res.data.songsList[0].totalCount.count)

                //index-number
                var temparr = res.data.songsList[0].data;
                temparr.map((temparr, index) => { temparr.serial = index + 1 });
                setSongs(temparr);

            }
            //setIsLoading(false)

        }).catch(err => {
            console.log(err)
        })
    };

    //datatable-head/values loop
    const columns = [

        {
            name: 'S.No',
            width: '7%',
            selector: row => <div><h6>{row.serial}</h6></div>
        },
        {
            name: 'Song Title',
            width: '27%',

            selector: row => <div>
                <h6>{row.songTitle}</h6>
                <audio
                    onPlay={
                        (e) => {
                            var audios = document.getElementsByTagName('audio');
                            for (var i = 0, len = audios.length; i < len; i++) {
                                if (audios[i] != e.target) {
                                    audios[i].pause();
                                }
                            }
                        }
                    }
                    src={serverUrl + row.destination + row.fileName}
                    controls controlsList="nodownload" />
            </div>,

        },
        {
            name: ' Album',
            width: '14%',
            selector: row => <div> <img
                src={serverUrl + row.coverDest + row.coverPic} width="80" height="80" /></div>
        },
        {
            name: 'Artist Name',
            width: '13%',
            selector: row => <div> <h6>{row.string[0].userName} </h6></div>,
            sortable: true,
            sortField: 'userName',
        },

        {
            name: 'Action',
            width: '7%',
            selector: row => <div>
                <ul className="list-inline m-0">
                    <li className="list-inline-item"  >
                        <img
                            src={require("../../../assets/images/myImages/delete.svg").default}
                            alt="delete"
                            width='24px'
                            onClick={
                                (e) => {
                                    setShow(true);
                                    setDeleteId(row._id)
                                }
                            }
                        /></li>
                </ul>
            </div>
        },

    ]

    //datatable-pagination
    function TablePaginationActions({ }) {

        return (
            <>
                <IconButton onClick={

                    (e) => {
                        setPagenumber(pagenumber + 1)
                        axios.post(baseUrl + 'api/web/getusersong', {
                            "userId": useRefUserId.current,
                            "limit": value,
                            "page": 1,
                            "search": search,
                            "sortColumn": sortColumn,
                            "sortOrder": sortOrder
                        }, {
                            headers: {
                                'Content-Type': 'application/json',
                            }
                        }
                        ).then((res) => {
                            if (res.data) {

                                var temparr = res.data.songsList[0].data;
                                temparr.map((temparr, index) => { temparr.serial = index + 1; });
                                setSongs(temparr);
                                setPagenumber(1)

                            }

                        }
                        )

                    }} disabled={pagenumber === 1} aria-label="first page">
                    <FirstPageIcon />
                </IconButton>
                <IconButton onClick={
                    (e) => {
                        setPagenumber(pagenumber - 1)
                        axios.post(baseUrl + 'api/web/getusersong', {
                            "userId": useRefUserId.current,
                            'limit': value,
                            'page': pagenumber - 1,
                            'search': search,
                            "sortColumn": sortColumn,
                            "sortOrder": sortOrder


                        }, {
                            headers: {
                                'Content-Type': 'application/json',

                            }
                        }
                        ).then((res) => {
                            if (res.data) {

                                var temparr = res.data.songsList[0].data;
                                temparr.map((temparr, index) => { temparr.serial = (index + 1 + ((value) * (pagenumber - 2))) });
                                setSongs(temparr);

                            }

                        }
                        )

                    }} disabled={pagenumber === 1} >
                    <KeyboardArrowLeft />
                </IconButton>
                <IconButton
                    onClick={

                        (e) => {
                            setPagenumber(pagenumber + 1)
                            axios.post(baseUrl + 'api/web/getusersong', {
                                "userId": useRefUserId.current,
                                'limit': value,
                                'page': pagenumber + 1,
                                'search': search,
                                "sortColumn": sortColumn,
                                "sortOrder": sortOrder


                            }, {
                                headers: {
                                    'Content-Type': 'application/json',

                                }
                            }
                            ).then((res) => {
                                if (res.data) {

                                    var temparr = res.data.songsList[0].data;

                                    temparr.map((temparr, index) => { temparr.serial = (index + 1 + (value * pagenumber)) });
                                    setSongs(temparr);

                                }

                            }
                            )

                        }}
                    disabled={PageCount === pagenumber}
                    aria-label="next page"
                >
                    <KeyboardArrowRight />
                </IconButton>
                <IconButton
                    onClick={

                        (e) => {
                            setPagenumber(pagenumber + 1)
                            axios.post(baseUrl + 'api/web/getusersong', {
                                "userId": useRefUserId.current,
                                'limit': value,
                                'page': PageCount,
                                'search': search,
                                "sortColumn": sortColumn,
                                "sortOrder": sortOrder


                            }, {
                                headers: {
                                    'Content-Type': 'application/json',

                                }
                            }
                            ).then((res) => {
                                if (res.data) {

                                    var temparr = res.data.songsList[0].data;
                                    temparr.map((temparr, index) => { temparr.serial = (index + 1 + (value * (PageCount - 1))) });

                                    setSongs(temparr);
                                    setPagenumber(PageCount)

                                }

                            }
                            )

                        }}
                    disabled={PageCount === pagenumber}
                    aria-label="last page"
                >
                    <LastPageIcon />
                </IconButton>
            </>
        );
    }

    const CustomMaterialPagination = ({ rowCount, onChangePage, onChangeRowsPerPage, currentPage }) => (
        <TablePagination
            component="nav"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={pagenumber - 1}
            onChangePage={(_, newPage) => {
                setPagenumber(newPage + 1);
                setValue(rowsPerPage);
            }}
            onRowsPerPageChange={(event) => {
                const newRowsPerPage = parseInt(event.target.value, 10);
                setRowsPerPage(newRowsPerPage);
                setPagenumber(1);
                setValue(newRowsPerPage);
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={[10, 25, 50, 100]}
        />
    );

    return (<>
        {loading ? <Spinner></Spinner> :
            <div className="container">
                <div className="componentsDetails d-flex justify-content-between bg-white p-3 my-5 shadow-sm rounded">
                    <div className="componentsDetailsInner text-center d-flex align-items-center">
                        {/*  <div> */}
                        <Col xxl={4} xl={4} lg={4} sm={4} md={5} xs={12}>

                            <Form >
                                <Form.Control
                                    type="default"
                                    className='bg-transparent my-3 border-radius-0'
                                    id="exampleFormControlInput1"
                                    placeholder="search "
                                    onChange={
                                        (e) => {

                                            setSearch(e.target.value)

                                        }
                                    } />

                            </Form>
                        </Col>
                        <h5 className="f-semibold m-0 ms-2">Total No of songs :<span className="f-bold ps-2 m-0">{totalCount}</span></h5>
                    </div>

                    {/*  </div> */}
                </div>
                <div className="text-center my-5 songUpload">
                    <div className="table-responsive adminTable userSongList stickerCattable mt-5">
                        <DataTable
                            columns={columns}
                            data={songs}
                            pagination
                            paginationServer
                            paginationComponent={CustomMaterialPagination}
                            sortServer
                            onSort={handleSort}

                        />
                    </div>
                </div>
                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header className='border-0' closeButton>
                        {/* <Modal.Title>Delete the Song</Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center ">
                            <h5 className="f-bold px-2  mb-4 ">
                                Are you sure want to delete the Song </h5>
                            <Button variant="danger" className="me-3 text-light"
                                onClick={(e) => {
                                    e.preventDefault();
                                    var local = JSON.parse(localStorage.getItem("UserInfo"))

                                    var t1 = local['token']

                                    const config = {
                                        headers: {
                                            'Content-Type': 'application/json',
                                            "Authorization": `Bearer ${t1}`,
                                        },
                                    }
                                    axios.post(baseUrl + "api/web/deletesong", { deleteId }, config).then(res => {


                                        if (res.data.status == 1) {

                                            setShow(false)
                                            DisplaySongsList()

                                        }
                                        else {

                                        }
                                    }).catch(err => {

                                    })
                                }}>Delete</Button>
                            <Button variant="secondary"
                                onClick={handleClose}>Cancel</Button>
                        </div>

                    </Modal.Body>

                </Modal>
            </div>
        }
    </>
    )
}

export default UserLanding
