import React from 'react'

const Landing = () => {
    return (
        <div className="sectionBase  col py-5">
            <div className="text-center yoyoBg">
                <h1>Singing may boosts your immune system</h1>

                <div className="col singImgContainer my-5">
                    <img src={`${process.env.PUBLIC_URL}/images/yoyobanner.svg`} alt="Singing" />
                </div>
            </div>
        </div>
    )
}

export default Landing
