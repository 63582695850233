import React, { useState } from 'react'
import { Modal, Button, Form, Spinner } from 'react-bootstrap';

/* import GoogleLogin from 'react-google-login'; */
import { login, registerUser, checkexistingemail } from '../../../store/actionCreators/userActions';
import { useDispatch, useSelector } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useGoogleLogin } from "@react-oauth/google";
import axios from 'axios';


const LoginModal = props => {
  /* variable for login */
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showSignUp, setShowSignUp] = useState(false);


  /* variables for signupform */
  const [signUpEmail, setSignUpEmail] = useState('');
  const [SignUpPassword, setSignUpPassword] = useState('');
  const [SignUpRePassword, setSignUpRePassword] = useState('');
  const [signUpDisplay, setSignUpDisplay] = useState('');

  /* variable for error validation form */
  const [signUpEmailErr, setSignUpEmailErr] = useState('');
  const [SignUpPasswordErr, setSignUpPasswordErr] = useState('');
  const [SignUpRePasswordErr, setSignUpRePasswordErr] = useState('');
  const [signUpDisplayErr, setSignUpDisplayErr] = useState('');

  const [user, setUser] = useState([]);

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const userRegister = useSelector((state) => state.userRegister)

  const { loading, error, UserInfo, emailcheckloading, emailcheckerror } = userLogin
  const { regLoading, regError, regUserInfo } = userRegister
  const [errMsg, setErrMsg] = useState('')


  const responseGoogle = (response) => {
    console.log(response);
  }

  const submitHandler = (e) => {

    e.preventDefault()
    if (email != '' && password != '') {

      dispatch(login(email, password))

      if (error == "" && error == null) {
        props.handleClose()
        notify()
      }
    }
    else {

      setErrMsg('Please Enter Your Email and Password')
    }

  }

  const emailValidate = (e) => {
    if (signUpEmail === "" || signUpEmail === null || !/.+@.+\.[A-Za-z]+$/.test(signUpEmail)) {
      setSignUpEmailErr("Enter  valid Email Id")
    }
    else {
      setSignUpEmailErr("")
    }
    /*  else {
       setSignUpEmailErr("")
       dispatch(checkexistingemail(signUpEmail))
       console.log(emailcheckerror);
       if (emailcheckerror != "" || emailcheckerror != null || emailcheckerror != undefined || emailcheckerror != 'undefined') {
         setSignUpEmailErr(emailcheckerror)
       }
     } */
  }
  const nameValidate = (e) => {
    if (signUpDisplay === "" || signUpDisplay == null) {
      setSignUpDisplayErr("Enter the Display name")
    }
    else {
      setSignUpDisplayErr("")
    }
  }
  const passwordValidate = (e) => {

    if (SignUpPassword === "" || SignUpPassword === null) {
      setSignUpPasswordErr("Enter the password")
    }
    else {
      setSignUpPasswordErr("")
    }
    if (SignUpRePassword === "" || SignUpRePassword === null) {
      setSignUpRePasswordErr("Enter the conform password")
    }
    else {
      setSignUpRePasswordErr("")
    }
    if (SignUpPassword !== SignUpRePassword) {
      setSignUpRePasswordErr("Password Miss Match")

    }


  }
  const SignUpsubmitHandler = (e) => {
    console.log('reg click');
    e.preventDefault()

    if (signUpDisplay != '' && signUpEmail != '' && SignUpPassword != '' && SignUpRePassword != '') {

      if (
        signUpEmailErr == "" &&
        SignUpPasswordErr == "" &&
        SignUpRePasswordErr == "" &&
        signUpDisplayErr == ""
      ) {

        dispatch(registerUser(signUpEmail, SignUpPassword, signUpDisplay))
        if (regError == "" && regError == null) {
          props.handleClose()
        }
      }
      else {
        console.log(signUpEmailErr, SignUpPasswordErr, SignUpRePasswordErr, signUpDisplayErr);
        emailValidate()
        nameValidate()
        passwordValidate()
      }
    }


    else {
      console.log('main else');
      emailValidate()
      nameValidate()
      passwordValidate()
      //alert('Please Fill all Fields')
    }
  }
  const handleLogin = async googleData => {
    const res = await fetch("/api/v1/auth/google", {
      method: "POST",
      body: JSON.stringify({
        token: googleData.tokenId
      }),
      headers: {
        "Content-Type": "application/json"
      }
    })
    const data = await res.json()
    console.log(data)
    // store returned user somehow
  }

  const customeGooglelogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse)
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => console.log("Login Failed:", error)
  });




  function handelEmailChange(e) {
    setSignUpEmail(e.target.value)
    emailValidate()
  }

  const notify = () => {
    toast.success('Logged In SuccessFully!', {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
  //close-handler
  const closeHandler = () => {
    props.handleClose()
    setEmail('')
    setPassword('')
    setSignUpDisplay('')
    setSignUpEmail('')
    setSignUpPassword('')
    setSignUpRePassword('')

    setErrMsg('')
    setSignUpDisplayErr('')
    setSignUpEmailErr('')
    setSignUpPasswordErr('')
    setSignUpRePasswordErr('')
  }

  return (
    <>
      <Modal show={props.show} onHide={closeHandler}>
        <Modal.Header closeButton className='border-0' onHide={closeHandler}>
          <Modal.Title >Login</Modal.Title>

        </Modal.Header>
        <Modal.Body className="pb-5">

          {!showSignUp ? <div className="signInBase">
            <Form onSubmit={submitHandler}>
              <div className="sign_in_inputs" >
                {/* <Button onClick={notify}>Hi</Button> */}


                <input type="email" name="login_email" value={email}
                  onChange={(e) => {

                    setEmail(e.target.value)
                    setErrMsg('')

                  }
                  } placeholder="Email Address" className="name_input" />
                <input type="password" value={password}
                  onChange={(e) => {
                    setPassword(e.target.value)
                    setErrMsg('')

                  }
                  } name="login_password" placeholder="Password" />
                <span class="error ms-0">{errMsg != '' ? errMsg : (error != '' ? error : '')}
                </span>
                <div className="col my-3">
                  <button
                    disabled={loading ?? false}
                    className="yellowBtn btnCustom"
                    type="submit"

                  >LogIn
                    {loading && (

                      <Spinner size='sm' animation='border' className='ms-2' />
                    )}
                  </button>
                  {/* {loading && <Spinner animation="grow" className='text-center' />} */}
                </div>
              </div>
            </Form>
            <div className="col mt-3 text-center">

              <Button type='button' className="gsi-material-button" onClick={() => customeGooglelogin()}>
                <div className="gsi-material-button-state" />
                <div className="gsi-material-button-content-wrapper">
                  <div className="gsi-material-button-icon">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ display: 'block' }}>
                      <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z" />
                      <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z" />
                      <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z" />
                      <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z" />
                      <path fill="none" d="M0 0h48v48H0z" />
                    </svg>
                  </div>
                  <span className="gsi-material-button-contents">Sign in with Google</span>
                  <span style={{ display: 'none' }}>Sign in with Google</span>
                </div>
              </Button>


            </div>
            {/*  <div className="text-center my-3">
            <span className="">OR</span>
          </div> */}
            {/* 
          <div className="col mt-3 text-center">

            <GoogleLogin className="googleBtn btnCustom"
              clientId="503169099548-gi02fmmpjad6p9231rfrndgmlj4vu1jk.apps.googleusercontent.com"
              buttonText="Login with Google"
              onSuccess={handleLogin}
              onFailure={handleLogin}
              cookiePolicy={'single_host_origin'}
            />
          </div> */}
            <div className="text-center my-3">
              <span className="">OR</span>
            </div>
            <button className="themeBtn btnCustom text-light" onClick={(e) => setShowSignUp(true)} >Register</button>
          </div>
            : <div className="signInBase">
              <Form onSubmit={SignUpsubmitHandler}>
                <div className="sign_in_inputs" >
                  <span className="error ms-0">{regError}</span>
                  <input type="text" name="signup_displayName" value={signUpDisplay} autoComplete="off"
                    onChange={(e) =>
                      setSignUpDisplay(e.target.value)
                    } placeholder="Display Name" className="name_input" /><span className="error">{signUpDisplayErr}</span>
                  <input type="email" name="signup_email" value={signUpEmail}
                    onChange={(e) => handelEmailChange(e)} placeholder="Email Address" className="name_input" autoComplete="off" />
                  <span className="error">{signUpEmailErr}</span>
                  <input type="password" value={SignUpPassword}
                    onChange={(e) => { setSignUpPassword(e.target.value) }} name="signup_password" placeholder="Password" />
                  <span className="error">{SignUpPasswordErr}</span>
                  <input type="password" value={SignUpRePassword}
                    onChange={(e) => setSignUpRePassword(e.target.value)} name="signup_repassword" placeholder="Confirm-Password" /><span className="error">{SignUpRePasswordErr}</span>
                  <div className="col my-3">
                    <button
                      disabled={regLoading ?? false}
                      className="yellowBtn btnCustom"
                      type="submit">Register

                      {regLoading && (

                        <Spinner size='sm' animation='border' className='ms-2' />
                      )}

                    </button>
                    {/*  {regLoading && <Spinner animation="grow" />} */}
                  </div>
                </div>
              </Form>

              <div className="text-center my-3">
                <span className="">OR</span>
              </div>
              <button className="themeBtn btnCustom text-light" onClick={(e) => setShowSignUp(false)} >LogIn</button>
            </div>}

        </Modal.Body>
      </Modal>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

    </>
  )
}

export default LoginModal
