import {
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,

} from '../constants/UserConstant'

export const UserRegisterReducer = (state = {}, action) => {

  switch (action.type) {
    case USER_REGISTER_REQUEST:
     
      return {
        regLoading: true,
        //regError: "loading"
      }
    case USER_REGISTER_SUCCESS:
      return {
        regLoading: false, 
        regUserInfo: action.payload,
        regError: null
      }
    case USER_REGISTER_FAIL:
      return {
        regLoading: false, 
        regError: action.payload
      }
      

    default:
      return state
  }
}