import React, { useState, useEffect } from 'react'
import LoginModal from './LoginModal'
import { Link } from 'react-router-dom'
import { GoogleOAuthProvider } from "@react-oauth/google";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { baseUrl } from '../../../config/constant';
import { Form, Modal, Spinner } from 'react-bootstrap';

const Footer = props => {

    const userLogin = useSelector((state) => state.userLogin);
    const dispatch = useDispatch()
    const { loading, error, UserInfo } = userLogin


    //request-song-modal
    const [showRequestSongModal, setShowRequestSongModal] = useState(false);
    const handleCloseRequestSongModal = () => setShowRequestSongModal(false);
    const handleShowRequestSongModal = () => setShowRequestSongModal(true);

    const [songName, setSongName] = useState('')
    const [songAbout, setSongAbout] = useState('')

    const [songNameErr, setSongNameErr] = useState('')
    const [songAboutErr, setSongAboutErr] = useState('');

    const [loader, setLoader] = useState(false);

    const [userId, setUserId, useRefUserId] = useState('');

    useEffect(() => {
        if (UserInfo != null) {
            setUserId(UserInfo._id)
        }
    }, [UserInfo])


    //close-handler
    const closeHandler = () => {
        handleCloseRequestSongModal()
        setSongName('')
        setSongAbout('')
        setSongNameErr('')
        setSongAboutErr('')
    }

    const submitHandler = (e) => {

        e.preventDefault()

        if (songName != '' && songAbout != '' && userId != "") {
            setLoader(true)

            var local = JSON.parse(localStorage.getItem("UserInfo"))

            var t1 = local['token']

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${t1}`,
                },
            }
            axios.post(
                baseUrl + 'api/web/sendsongrequest',
                {
                    "userId": userId,
                    "songtitle": songName,
                    "aboutsong": songAbout,

                },
                config
            ).then(res => {
                setLoader(false)

                if (res.data != null) {

                    if (res.data.status == 0) {
                        alert(res.data.message)
                    }
                    else {
                        alert('Your song request has been sent')
                        closeHandler()

                    }


                }
                //setLoading(false)
            }).catch(err => {
                console.log(err)
                //setLoading(false)
            })


        }
        else {
            if (songName == '') {

                setSongNameErr('Enter song name')
            }
            else {
                setSongNameErr('')
            }
            if (songAbout == '') {

                setSongAboutErr('Enter song details')
            }
            else {
                setSongAboutErr('')
            }


        }

    }


    return (
        <> <div className="footerBase pt-5">
            <div className='container'>
                <div className='row'>
                    <div className="col-12 col-xxl-6 col-xl-6 col-lg-6 col-sm-6 col-md-6">
                        <ul className="list-unstyled">
                            <li className='mb-4'><Link to="/privacy-policy" className='text-light text-decoration-none'>Privacy policy</Link></li>
                            <li className='mb-4'><a href="/terms-of-use" className='text-light text-decoration-none'>Terms and Conditions</a></li>
                            {UserInfo ?
                                <li className='mb-4'><a href className='text-light text-decoration-none cursor-pointer' onClick={(e) => {
                                    e.preventDefault()
                                    handleShowRequestSongModal()
                                }}>Request Song</a></li> : <></>}
                        </ul>
                    </div>
                    <div className="col-12 col-xxl-6 col-xl-6 col-lg-6 col-sm-6 col-md-6 text-end">
                        <h2 className="mb-3">Download the App Now!</h2>
                        <ul className="list-inline downloadUl">
                            <li className="list-inline-item"><a target='_blank' href="https://play.google.com/store/apps/details?id=com.yoyosinger.yoyo"><img src={`${process.env.PUBLIC_URL}/images/google.png`} alt="Play Store" /></a></li>
                            <li className="list-inline-item"><a target='_blank' href="#"><img src={`${process.env.PUBLIC_URL}/images/app.png`} alt="App Store" /></a></li>
                        </ul>
                    </div>
                </div>
            </div>


            <div>

            </div>

            <div className='text-center mt-5'>
                <p className='text-light '>Copyrights <span>&#169;</span> YOYO SINGER PRIVATE LIMITED</p>
                <p className='text-light pb-3'>Design and Developed @ <a target='_blank' className='text-light text-decoration-none' href='https://www.pictuscode.com/'>Pictuscode PVT LTD</a></p>
            </div>
        </div>

            {/*   request-song-modal  */}
            <Modal show={showRequestSongModal} onHide={closeHandler}>
                <Modal.Header closeButton className='border-0' onHide={closeHandler}>
                    <Modal.Title >Request Song</Modal.Title>

                </Modal.Header>
                <Modal.Body className="pb-5">

                    <div className="signInBase RequestSongModal">
                        <Form onSubmit={submitHandler}>
                            <div className="sign_in_inputs " >


                                <input type="text" name="song_name" value={songName}
                                    onChange={(e) => {
                                        setSongName(e.target.value)
                                        if (e.target.value == '') {
                                            setSongNameErr('Enter song name')
                                        }
                                        else {
                                            setSongNameErr('')
                                        }

                                    }
                                    } placeholder="Enter Song Name" className="name_input mb-0 text-capitalize" />
                                <span class="error ms-0">{songNameErr}
                                </span>

                                <Form.Control
                                    as="textarea"
                                    value={songAbout}
                                    className='rounded-1 mt-3 text-capitalize'
                                    placeholder="Enter  Movie Name/Album Name, Year etc., "
                                    rows={5}
                                    name="song_about"
                                    onChange={(e) => {
                                        setSongAbout(e.target.value)
                                        if (e.target.value == '') {
                                            setSongAboutErr('Enter song details')
                                        }
                                        else {
                                            setSongAboutErr('')
                                        }
                                    }
                                    }
                                />
                                <span class="error ms-0">{songAboutErr}
                                </span>
                                <div className="col my-3">
                                    <button
                                        disabled={loader ?? false}
                                        className="yellowBtn btnCustom"
                                        type="submit"

                                    >Request
                                        {loader && (

                                            <Spinner size='sm' animation='border' className='ms-2' />
                                        )}
                                    </button>

                                </div>
                            </div>
                        </Form>
                    </div>


                </Modal.Body>
            </Modal>

            <GoogleOAuthProvider clientId="503169099548-gi02fmmpjad6p9231rfrndgmlj4vu1jk.apps.googleusercontent.com">
                <LoginModal show={props.show} handleClose={() => props.handleClose()} />
            </GoogleOAuthProvider>


        </>

    )
}

export default Footer
