import React, { useRef, useEffect, useCallback } from 'react'
import useState from 'react-usestateref'
import { Link, useNavigate } from 'react-router-dom'
import UploadBredcumb from './UploadBredcumb'

import { useSelector, useDispatch } from "react-redux"

import AudioPlayer from './AudioPlayer'
import { ArrowCounterclockwise } from 'react-bootstrap-icons';

import beep from '../../../assets/images/myImages/beep.mp3'

import { Spinner } from 'react-bootstrap';
import axios from 'axios';
import { baseUrl } from '../../../config/constant'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const LyricsTiming = () => {
        let navigate = useNavigate()
        const userLogin = useSelector((state) => state.userLogin)
        const { UserInfo } = userLogin
        const [loading, setLoading] = useState(false)
        const [lyricsTemp, setLyricsTemp, useRefLyricsTemp] = useState([])
        const [currentTime, setCurrentTime, useRefCurrentTime] = useState(0)
        const [currentLine, setCurrentLine, useRefCurrentLine] = useState(0)
        const [showCountDown, setshowCountDown, useRefshowCountDown] = useState(false)
        const [countDown, setCountDown, useRefCountDown] = useState(3)
        const [songSrc, setsongSrc] = useState('')
        const [maxLine, setMaxLine, useRefMaxLine] = useState(0)
        const [remainingLine, setRemainingLine, useRefRemainingLine] = useState(0)
        const [err, setErr, useRefSetErr] = useState("")

        const [playerData, setPlayerData, useRefPlayerData] = useState()
        const [onclicked, setOnClicked, useRefOnClicked] = useState(false)
        const [swalProps, setSwalProps] = useState({});
        const MySwal = withReactContent(Swal);

        var comp = ""
        function handleSwalClick() {
                setSwalProps({
                        show: true,
                        title: 'Success',
                        text: 'Uploaded successfully, Thank you for uploading',
                });

                MySwal.fire({
                        ...swalProps,
                        didOpen: () => {
                                // run when swal is opened...
                        },
                        didClose: () => {
                                navigate('/mysongs');
                        },
                }).then((result) => {
                        if (result.isConfirmed) {
                                navigate('/mysongs');
                        } else if (result.dismiss === Swal.DismissReason.error) {
                                // run when promise rejected...
                        } else if (result.dismiss) {
                                // run when promise is resolved...
                        }
                }).catch((error) => {
                        // run when there is an error...
                });
        }
        const audioRef = useRef()
        const secToHMS = (secs) => {
                var sec_num = parseInt(secs, 10)
                var minutes = atLeast2Digit(Math.floor(sec_num / 60) % 60)
                var seconds = atLeast2Digit(sec_num % 60)
                return minutes + ":" + seconds

        }
        function atLeast2Digit(n) {
                n = parseInt(n);
                var ret = n > 9 ? "" + n : "0" + n;
                return ret;
        }
        function skipForEmptyLine() {
                if (useRefCurrentLine.current < maxLine - 1) {
                        if (lyricsTemp[useRefCurrentLine.current + 1]["line"] == "") {

                                lyricsTemp[useRefCurrentLine.current + 1]["time"] = audioRef.current.audio.current.currentTime
                                setLyricsTemp(lyricsTemp)
                                console.log(useRefCurrentLine.current)
                                setCurrentLine(useRefCurrentLine.current + 1)

                                setRemainingCount()
                                skipForEmptyLine()
                        }
                        else { return }
                }
                else { return }
        }
        function setRemainingCount() {
                let numRows = []
                numRows = lyricsTemp.filter(function (item) {

                        return item.time == "00:00"
                });

                setRemainingLine(numRows.length)


        }
        function updateTime() {
                if (playerData !== undefined && !playerData.paused) {
                        lyricsTemp[useRefCurrentLine.current]["time"] = audioRef.current.audio.current.currentTime
                        setLyricsTemp(lyricsTemp)
                        skipForEmptyLine();
                        setRemainingCount();
                        console.log(lyricsTemp)
                        if (useRefCurrentLine.current < maxLine - 1) {
                                setRemainingCount()
                                setCurrentLine(useRefCurrentLine.current + 1)
                                console.log(useRefCurrentLine.current)
                                const section = document.querySelector(`#line_${useRefCurrentLine.current}`);
                                section.scrollIntoView({ behavior: 'smooth', block: 'start' });

                        }
                }
                else if (playerData !== undefined && playerData.paused) {
                        playerData.play()
                }


        }

        useEffect(() => {
                const handleUserKeyPress = event => {
                        const { key, keyCode } = event;
                        if (keyCode === 32) {
                                event.preventDefault();

                                if (audioRef.current.audio.current.currentTime < audioRef.current.audio.current.duration) {
                                        updateTime();
                                }
                                else {

                                }
                        }
                };
                window.addEventListener("keydown", handleUserKeyPress);
                return () => {
                        window.removeEventListener("keydown", handleUserKeyPress);
                };
        }, [lyricsTemp, currentLine, playerData]);


        useEffect(() => {
                setLoading(true)
                loadData();
                setLoading(false)

        }, [UserInfo]);
        async function loadData() {
                const userId = UserInfo._id
                console.log(UserInfo._id)
                var local = JSON.parse(localStorage.getItem("UserInfo"))

                var t1 = local['token']

                const config = {
                        headers: {
                                'Content-Type': 'application/json',
                                "Authorization": `Bearer ${t1}`,
                        },
                }

                axios.post(
                        baseUrl + 'api/web/getuserdata',
                        {
                                userId,
                        },
                        config
                ).then(res => {
                        console.log(res.data.songDraft)
                        if (res.data.songDraft != null && res.data.songDraft.isDrafted) {
                                if (res.data.songDraft.lyricsTemp != undefined) {
                                        setLyricsTemp(res.data.songDraft.lyricsTemp)
                                        setMaxLine(res.data.songDraft.lyricsTemp.length)
                                        setRemainingLine(res.data.songDraft.lyricsTemp.length)
                                        setRemainingCount()
                                        setsongSrc(baseUrl + res.data.songDraft.destination + "" + res.data.songDraft.fileName)

                                }
                        }

                }).catch(err => {
                        console.log(err)
                })

        }

        comp = lyricsTemp.map(function (ln) {
                if (ln.line == "") {

                        return (
                                <></>
                        )
                }
                else {
                        return (
                                <div key={ln._id}>
                                        <div className="row m-0">
                                                <div id={'line_' + ln._id} className={`col-xxl-12 col-xl-12 col-lg-12 col-sm-12 col-md-12 selectTiming ${useRefCurrentLine.current == ln._id ? "activeLine" : ""}`} onClick={
                                                        (e) => {

                                                                if (ln.time !== "00:00" && useRefCurrentLine.current != ln._id) {
                                                                        setOnClicked(true)
                                                                        setCurrentLine(ln._id)
                                                                        audioRef.current.audio.current.currentTime = ln.time

                                                                }


                                                        }}>{ln.time == "00:00" && useRefCurrentLine.current == ln._id ? currentTime != undefined ? secToHMS(currentTime) : secToHMS(ln.time) : secToHMS(ln.time)}--{ln.line}
                                                        {useRefCurrentLine.current == ln._id && showCountDown && <span className={`alert alert-success`}>
                                                                {useRefCountDown.current}
                                                        </span>}

                                                        {
                                                                (ln.time !== "00:00") &&
                                                                <ul className="list-inline float-end m-0">
                                                                        <li className="list-inline-item ">

                                                                                <span className="pe-auto"><ArrowCounterclockwise onClick={(e) => {
                                                                                        clearInterval(myVar);
                                                                                        setshowCountDown(false)
                                                                                        setCountDown(3)
                                                                                        if ((ln.time != "00:00")) {
                                                                                                const beeps = new Audio(beep);
                                                                                                audioRef.current.audio.current.currentTime = ln.time
                                                                                                playerData.pause()
                                                                                                var temp = lyricsTemp;
                                                                                                for (var i = ln._id; i < lyricsTemp.length; i++) {
                                                                                                        temp[i]["time"] = "00:00"
                                                                                                }
                                                                                                setRemainingCount()


                                                                                                setshowCountDown(true)
                                                                                                setTimeout(() => {
                                                                                                        setshowCountDown(false)
                                                                                                        playerData.play()
                                                                                                        clearInterval(myVar);
                                                                                                        setCountDown(3)
                                                                                                }, 3000)

                                                                                                var myVar = setInterval(() => {

                                                                                                        setCountDown(countDown - 1)
                                                                                                        beeps.play()

                                                                                                }, 1000);


                                                                                                setCurrentLine(ln._id)
                                                                                        }
                                                                                }
                                                                                } className={`${useRefCurrentLine.current == ln._id ? "primaryColor" : "themeColor"}`} size={25} /> </span> </li>
                                                                </ul>
                                                        }
                                                </div>
                                        </div>
                                </div>
                        )
                }
        });

        return (
                <>
                        {/* <SweetAlert2 {...swalProps}
                                didOpen={() => {
                                        // run when swal is opened...
                                }}
                                didClose={() => {
                                        navigate('/mysongs')
                                }}
                                onConfirm={result => {
                                        navigate('/mysongs')
                                }}
                                onError={error => {
                                        // run when promise rejected...
                                }}
                                onResolve={result => {
                                        // run when promise is resolved...
                                }}
                        /> */}

                        <div className="counter  position-fixed top-50 end-0">
                                <h1>{useRefRemainingLine.current


                                }<ArrowCounterclockwise onClick={(e) => {
                                        const restdata = lyricsTemp;
                                        for (var i = 0; i < lyricsTemp.length; i++) {
                                                restdata[i]["time"] = "00:00"
                                        }
                                        setLyricsTemp(restdata)
                                        setMaxLine(restdata.length)
                                        setRemainingCount()
                                        setCurrentLine(0)
                                        const section = document.querySelector(`#line_0`);
                                        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
                                        audioRef.current.audio.current.currentTime = 0
                                        audioRef.current.audio.current.pause()

                                }
                                } className="themebg" size={25} /> </h1>
                        </div>
                        {!loading ? <><UploadBredcumb activeStep={4} />
                                <div className="col p-0" >
                                        <div className="container">
                                                <div className=" my-5 songUpload">
                                                        <h1 className="text-center">Enter Timing</h1>
                                                        <div className="col songUploadInner py-4">
                                                                <div className="col-12 p-0 text-left">
                                                                        {comp}
                                                                </div>
                                                                <h3 className="mt-4"></h3>
                                                        </div>
                                                        <div className=" pt-3 d-flex flex-row justify-content-between ">
                                                                <Link to="/lyricsupload" className="submitBtn float-start"> Back </Link>
                                                                {
                                                                        (useRefRemainingLine.current == 0) &&

                                                                        <a to="/lyricstiming" onClick={(e) => {
                                                                                setRemainingCount()
                                                                                if (useRefRemainingLine.current == 0) {
                                                                                        setLoading(true)
                                                                                        var userId = UserInfo._id

                                                                                        var local = JSON.parse(localStorage.getItem("UserInfo"))

                                                                                        var t1 = local['token']

                                                                                        const config = {
                                                                                                headers: {
                                                                                                        'Content-Type': 'application/json',
                                                                                                        "Authorization": `Bearer ${t1}`,
                                                                                                },
                                                                                        }

                                                                                        axios.post(baseUrl + "api/web/musicdetailsstep4", { userId, lyricsTemp }, config).then(res => {
                                                                                                setLoading(false)

                                                                                                if (res.data.status == 1) {
                                                                                                        handleSwalClick()



                                                                                                }
                                                                                                else {

                                                                                                }
                                                                                        }).catch(err => {
                                                                                                setLoading(false)
                                                                                                setErr("Failed to update")

                                                                                        })


                                                                                }
                                                                                else {
                                                                                        setErr("set time to all the lines")
                                                                                }


                                                                        }} className="submitBtn float-end"> Next </a>}
                                                        </div>
                                                        <p className="mt-5"><sup>*</sup>  By uploading this song, you confirm that you are complying with all legal guidelines set forth by our Community Guidelines and Terms of Service.If you have any questions, please refer to our FAQ or contact YoYo Singer for more assistance.</p>
                                                </div>
                                        </div>
                                </div>
                                <AudioPlayer
                                        songSrc={songSrc}
                                        audioRef={audioRef}
                                        onLoadedData={(e) => {
                                                setPlayerData(e.currentTarget)
                                                e.currentTarget.pause()

                                        }}
                                        onSeeked={(e) => {
                                                if (!onclicked) {
                                                        console.log(e.currentTarget.currentTime)
                                                        console.log(useRefCurrentLine.current)
                                                        let cost = lyricsTemp;
                                                        let smallCost = cost.filter(function (costItem) {
                                                                return costItem.time >= e.currentTarget.currentTime
                                                        });
                                                        if (smallCost != undefined && smallCost.length > 0) {
                                                                let getId = smallCost[0]['_id']
                                                                setCurrentLine(getId)
                                                                console.log(getId);
                                                                const section = document.querySelector(`#line_${getId}`);
                                                                section.scrollIntoView({ behavior: 'smooth', block: 'start' });
                                                        }
                                                }
                                                else {
                                                        setOnClicked(false)
                                                }
                                        }}
                                        onListen={(e) => {
                                                if (playerData === undefined) {
                                                        setPlayerData(e.currentTarget)

                                                }
                                                setCurrentTime(e.currentTarget.currentTime)
                                                if (useRefCurrentLine.current < maxLine - 1) {
                                                        if (lyricsTemp[useRefCurrentLine.current]["time"] != "00:00") {

                                                                if (lyricsTemp[useRefCurrentLine.current + 1]["time"] <= e.currentTarget.currentTime) {
                                                                        skipForEmptyLine();
                                                                        setCurrentLine(useRefCurrentLine.current + 1)
                                                                }
                                                                else if (lyricsTemp[useRefCurrentLine.current + 1]["time"] == "00:00" && lyricsTemp[useRefCurrentLine.current]["time"] < e.currentTarget.currentTime) {
                                                                        skipForEmptyLine();
                                                                        setCurrentLine(useRefCurrentLine.current + 1)
                                                                }
                                                        }
                                                }

                                        }}
                                /></>
                                :
                                <div className='d-flex align-items-center justify-content-center min-vh-100'>

                                        <Spinner animation="grow" />
                                </div>
                        }
                </>
        )

}


export default LyricsTiming
