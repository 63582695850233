export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const SET_LYRICS = 'SET_LYRICS'
export const SET_CURRENT_LINE_AT = 'SET_CURRENT_LINE_AT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'


export const USER_CHECK_EMAIL_REQUEST = 'USER_CHECK_EMAIL_REQUEST'
export const USER_CHECK_EMAIL_SUCCESS = 'USER_CHECK_EMAIL_SUCCESS'
export const USER_CHECK_EMAIL_FAIL = 'USER_CHECK_EMAIL_FAIL'

export const ADMIN_LOGIN_REQUEST = 'ADMIN_LOGIN_REQUEST'
export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS'
export const ADMIN_LOGIN_FAIL = 'ADMIN_LOGIN_FAIL'
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT'

export const SET_SIDEBAR = 'SET_SIDEBAR'

