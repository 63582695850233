import React from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  ColumnDef,
  FilterFn,
} from '@tanstack/react-table';
import { matchSorter } from 'match-sorter';

// Define a default UI for filtering
function GlobalFilter({
  globalFilter,
  setGlobalFilter,
}) {
  const [value, setValue] = React.useState(globalFilter);

  return (
    <input
      value={value || ""}
      onChange={e => {
        setValue(e.target.value);
        setGlobalFilter(e.target.value);
      }}
      placeholder={`Search`}
      className="input-control shadow-sm w-100 m-0"
      style={{
        fontSize: '1.1rem',
        border: '0',
      }}
    />
  );
}

function DefaultColumnFilter({
  column: { filterValue, setFilter },
}) {
  return (
    <input
      value={filterValue || ''}
      onChange={e => setFilter(e.target.value)}
    />
  );
}

// Custom fuzzy filter function
const fuzzyTextFilterFn: FilterFn<any> = (rows, columnId, filterValue) => {
  return matchSorter(rows, filterValue, { keys: [row => row.values[columnId]] });
};
fuzzyTextFilterFn.autoRemove = val => !val;

function DataTable({ columns, data }) {
  const [globalFilter, setGlobalFilter] = React.useState('');
  const [columnFilters, setColumnFilters] = React.useState({});

  const table = useReactTable({
    columns,
    data,
    state: {
      globalFilter,
      columnFilters,
    },
    filterFns: {
      fuzzyText: fuzzyTextFilterFn,
    },
    globalFilterFn: fuzzyTextFilterFn,
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <>
      <div className="table-responsive adminTable">
        <div className="row d-flex justify-content-between m-0">
          <div className="col-12 col-md-3 col-sm-3 col-lg-2 col-xxl-2 col-xl-2 p-0">
            <select
              className="select-control shadow-sm"
              value={table.getState().pagination.pageSize}
              onChange={e => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-md-3 col-sm-3 col-lg-3 col-xxl-3 col-xl-3 p-0">
            <GlobalFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </div>
        </div>

        <table>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    {header.column.getCanFilter() ? (
                      <div>
                        {header.column.columnDef.filterFn === fuzzyTextFilterFn ? (
                          <DefaultColumnFilter
                            column={header.column}
                          />
                        ) : null}
                      </div>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>
                    {flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext()
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

        <div>
          <button
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>
          <span>
            Page {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </span>
          <span>
            | Go to page:
            <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              style={{ width: '100px' }}
            />
          </span>
        </div>
      </div>
    </>
  );
}

export default DataTable;
