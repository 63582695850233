import axios from 'axios';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { baseUrl } from '../../../config/constant';

const CommanPage = () => {
    const { slug } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [pageData, setPageData] = useState("");

    useEffect(() => {
        setIsLoading(true);
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        axios.post(
            baseUrl + 'api/web/getcmspage',
            {
                pageSlug: slug,
            },
            config
        )
            .then(res => {
                if (res.data) {
                    if (res.data && res.data.status === 1) {

                        console.log(res.data.pageDetails.content)
                        const sanitizedContent = res.data.pageDetails.content.toString().replaceAll("<h2><br />", '<h2>');
                        setPageData(sanitizedContent);
                    } else {
                        setPageData(`<h1>${slug}</h1>`);
                    }
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.error(err);
                setIsLoading(false);
            });
    }, [slug]);

    return (
        <div className="PrivacyBase min-vh-100">
            <div className="container col py-5">
                <div className="text-dark px-5">
                    {isLoading ? (
                        <p>Loading...</p>
                    ) : (
                        parse(pageData)
                    )}
                </div>
            </div>
        </div>
    );
};

export default CommanPage;
