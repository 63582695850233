import axios from 'axios';
import React, { useEffect } from 'react';
import { Form, Modal, Spinner } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import useState from 'react-usestateref';
import { baseUrl } from '../../../config/constant';
import { logout } from '../../../store/actionCreators/userActions';



const Header = props => {
    let navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [meta, setMeta] = useState(null)
    const userLogin = useSelector((state) => state.userLogin)

    const dispatch = useDispatch()
    const { loading, error, UserInfo } = userLogin

    //request-song-modal
    const [showRequestSongModal, setShowRequestSongModal] = useState(false);
    const handleCloseRequestSongModal = () => setShowRequestSongModal(false);
    const handleShowRequestSongModal = () => setShowRequestSongModal(true);

    const [songName, setSongName] = useState('')
    const [songAbout, setSongAbout] = useState('')

    const [songNameErr, setSongNameErr] = useState('')
    const [songAboutErr, setSongAboutErr] = useState('')

    const [userId, setUserId, useRefUserId] = useState('')
    const [loader, setLoader] = useState(false)


    const logoutHandler = () => {
        dispatch(logout())
    }
    const musicupload = () => {
        if (UserInfo != null) {

            navigate("musicupload")
        }
        else {
            props.handleShow()
        }

    }

    const stickerUpload = () => {
        if (UserInfo != null) {
            navigate("stickerUpload")
        }
        else {
            props.handleShow()
        }

    }

    useEffect(() => {
        GetMeta()


    }, [UserInfo])

    const matcher = window.matchMedia("(prefers-color-scheme: dark)");
    //console.log(matcher.matches)
    var favIcon = "fav1.ico";
    if (matcher.matches) {
        favIcon = "fav2.ico";
    }
    else {
        favIcon = "fav1.ico";
    }

    const GetMeta = () => {
        setIsLoading(true)
        if (UserInfo != null) {

            setUserId(UserInfo._id)
        }
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }
        axios.post(
            baseUrl + 'api/web/getmeta',
            {

            },
            config
        ).then(res => {


            if (res.data.meta != null) {

                setMeta(res.data.meta)


            }
            setIsLoading(false)

        }).catch(err => {
            console.log(err)
            setIsLoading(false)
        })
    }


    //close-handler
    const closeHandler = () => {
        handleCloseRequestSongModal()
        setSongName('')
        setSongAbout('')
        setSongNameErr('')
        setSongAboutErr('')
    }

    const submitHandler = (e) => {

        e.preventDefault()
        console.log('abcd');
        if (songName != '' && songAbout != '') {
            setLoader(true)

            var local = JSON.parse(localStorage.getItem("UserInfo"))

            var t1 = local['token']

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${t1}`,
                },
            }
            axios.post(
                baseUrl + 'api/web/sendsongrequest',
                {
                    "userId": useRefUserId.current,
                    "songtitle": songName,
                    "aboutsong": songAbout,

                },
                config
            ).then(res => {
                setLoader(false)

                if (res.data != null) {

                    if (res.data.status == 0) {
                        alert(res.data.message)
                    }
                    else {
                        alert('Your song request has been sent')
                        closeHandler()

                    }


                }
                //setLoading(false)
            }).catch(err => {
                console.log(err)
                //setLoading(false)
            })


        }
        else {
            if (songName == '') {

                setSongNameErr('Enter song name')
            }
            else {
                setSongNameErr('')
            }
            if (songAbout == '') {

                setSongAboutErr('Enter song details')
            }
            else {
                setSongAboutErr('')
            }


        }

    }

    return (
        <>
            <div className='YOYO Application'>
                {isLoading ?
                    <div className='d-flex align-items-center min-vh-100 justify-content-center'>
                        <Spinner animation="border" role="status">
                           
                        </Spinner>
                    </div>
                    : <><Helmet>
                        <meta charSet="utf-8" />
                        <title>{meta != null ? meta['siteName'] : "YOYO"}</title>

                        <link rel="icon" href={favIcon} />
                        <meta name="description" content={meta != null ? meta['metaDesc'] : "Yoyo"}></meta>
                        <meta name="keywords" content={meta != null ? meta['metaKey'] : "Yoyo"}></meta>

                        <meta property="og:title" content={meta != null ? meta['metaTitle'] : "Yoyo"}></meta>
                        <meta property="og:url" content={meta != null ? meta['serverUrl'] : "https://www.yoyosinger.com"}></meta>

                        <meta property="og:description" content={meta != null ? meta['metaDesc'] : "Yoyo"}></meta>
                        <meta property="og:image" content={meta != null ? `${meta['serverUrl']}${meta['logoFolder']}${meta['logo']}` : "logo"}></meta>
                    </Helmet>

                        <div className="homePageBase container-fluid col-12">
                            <div className="homePageInner col-12 nopadd h-100">
                                <div className="row m-0 headBorder">
                                    <div className=" col">
                                        <div className=" d-flex flex-row">
                                            <div className="logo mt-3 me-5 mb-3  d-inline-flex">

                                                <Link to="/"><img src={meta != null ? `${meta['serverUrl']}${meta['logoFolder']}${meta['logo']}` : "logo"} alt="Logo" /></Link>
                                            </div>

                                            {/* <div className="uploadBtnBase ">
                                        <button className='yellowBtn border-0 my-4 f-semibold' onClick={musicupload}> Upload Song </button>
                                    </div> */}
                                        </div>

                                    </div>

                                    {UserInfo == null ? <div className="col">
                                        <div className="loginBtn  text-end">
                                            <a href="#" onClick={props.handleShow} className="yellowBtn f-semibold my-4">
                                                <span className="keyIcon"></span> Login</a>
                                        </div>
                                    </div>
                                        :

                                        <div className="col">
                                            <div className="loginBtn  text-end mt-4">
                                                <div className="dropdown pe-4">
                                                    <span className="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <img src={UserInfo['profilePic'] != '' && UserInfo['profilePic'] != null ? UserInfo['serverUrl'] + '' + UserInfo['profilePicDir'] + '' +
                                                            UserInfo['profilePic'] : baseUrl + 'uploads/files/mh1695120947974.png'} width='50' height='50' alt='profilepic' />
                                                    </span>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" onClick={musicupload} >Song Upload</a></li>
                                                        <li><a className="dropdown-item" href={'/mysongs'}>Song List</a></li>
                                                        <li><a className="dropdown-item" href onClick={(e) => {
                                                            e.preventDefault()
                                                            handleShowRequestSongModal()
                                                        }}>Request Song</a></li>
                                                        <li><a className="dropdown-item" onClick={() => logoutHandler()} >Logout</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>


                                    }
                                </div>
                            </div>
                        </div></>}
            </div>
            {/*   request-song-modal  */}
            <Modal show={showRequestSongModal} onHide={closeHandler}>
                <Modal.Header closeButton className='border-0' onHide={closeHandler}>
                    <Modal.Title >Request Song</Modal.Title>

                </Modal.Header>
                <Modal.Body className="pb-5">

                    <div className="signInBase RequestSongModal">
                        <Form onSubmit={submitHandler}>
                            <div className="sign_in_inputs " >


                                <input type="text" name="song_name" value={songName}
                                    onChange={(e) => {
                                        setSongName(e.target.value)
                                        if (e.target.value == '') {
                                            setSongNameErr('Enter song name')
                                        }
                                        else {
                                            setSongNameErr('')
                                        }

                                    }
                                    } placeholder="Enter Song Name" className="name_input mb-0 text-capitalize" />
                                <span class="error ms-0">{songNameErr}
                                </span>

                                <Form.Control
                                    as="textarea"
                                    value={songAbout}
                                    className='rounded-1 mt-3 text-capitalize'
                                    placeholder="Enter  Movie Name/Album Name, Year etc., "
                                    rows={5}
                                    name="song_about"
                                    onChange={(e) => {
                                        setSongAbout(e.target.value)
                                        if (e.target.value == '') {
                                            setSongAboutErr('Enter song details')
                                        }
                                        else {
                                            setSongAboutErr('')
                                        }
                                    }
                                    }
                                />
                                <span class="error ms-0">{songAboutErr}
                                </span>
                                <div className="col my-3">
                                    <button
                                        disabled={loader ?? false}
                                        className="yellowBtn btnCustom"
                                        type="submit"

                                    >Request
                                        {loader && (

                                            <Spinner size='sm' animation='border' className='ms-2' />
                                        )}
                                    </button>

                                </div>
                            </div>
                        </Form>
                    </div>


                </Modal.Body>
            </Modal>

        </>
    )
};

export default Header