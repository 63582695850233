import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AdminProtectedRoute = ({ children }) => {
    const adminLogin = useSelector((state) => state.adminLogin);

    if (!adminLogin || !adminLogin.AdminInfo) {
        return <Navigate to="/admin" />;
    }

    return children;
};

export default AdminProtectedRoute;
