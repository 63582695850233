import {
  SET_SIDEBAR
} from '../constants/UserConstant'

export const SidebarReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_SIDEBAR:
      console.log( action.payload)
      return {
        sidebarShow: action.payload
      }
   
    default:
      return state
  }
}