import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { baseUrl } from '../../../config/constant';
import parse from 'html-react-parser'


const Privacy = (props) => {
   
    const [isLoading, setIsLoading] = useState(false)
    const [pageData, setPageData] = useState("")

    useEffect(() => {
        setIsLoading(true)
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }
        axios.post(
            baseUrl + 'api/web/getPage',
            {
                pageSlug: props.pageSlug
            },
            config
        ).then(res => {

            if (res.data != null) {
                
                var st = res.data.page.toString().replaceAll("<h2><br />", '<h2>');
               
                setPageData(st)
            }
            setIsLoading(false)

        }).catch(err => {
            console.log(err)
            setIsLoading(false)
        })

    }, [])

    return (
        <>
            <div className="PrivacyBase min-vh-100">
                <div className=" container col py-5 ">
                    <div className="text-dark  px-5 "  >
                        {parse(pageData)}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Privacy